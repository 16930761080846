import { decorate, observable, action } from "mobx";
import { create, persist } from "mobx-persist";

class PlanStore {
    planName = "";
    productName = "Samsung Phillippines (D2C) ADLD - 1 Year";
    productId = "SES_PH_D2C_1Y";
    planId = "PHLSTG20200414000676";
    productTenure = 2;
    productTenureType = "年期";
    bandNo = "1.0";
    productPrice = 1299;
    actualAmountWithoutPromotion = 1299;
    coverageDesc = "ADLD";
    currency = "NT$";
    whatsCovered = "";
    isSubscription = false;
    selectedTenure = null;
    orderNo = "";
    invoiceNumber = "";
    selectedProduct = "";
    actualProductPrice = "";
    discountedProductPrice = 12.9;
    paymentSystemPrice = "";
    productList = [
        {
            clientProductDescription:
                "Samsung Phillippines (D2C) ADLD - 1 Year",
            clientProductName: "Samsung Phillippines (D2C) ADLD - 1 Year",
            coverageDesc: "Cracked Screen and Other Damage",
            covered: [
                "Accidental & liquid breakage repair coverage",
                "Hassle-free repair requests",
                "Free pick-up & delivery",
                "Repairs by Galaxy experts",
            ],
            description: null,
            isSubscription: false,

            plans: {
                actualProductPrice: 1299,
                bandNo: "1.0",
                coverage: "ADLD",
                currency: "MYR",
                devicePriceEndingRange: 10000,
                devicePriceStartingrange: 0,
                discountedProductPrice: 1299,
                fulfillmentSystemPrice: 1299,
                paymentSystemPrice: 1299,
                planId: "PHLSTG20200414000676",
                productId: "PHLSTG20200414000676",
                totalRRPValueWithTax: 1299,
            },
            productCoverage: ["Physical Breakage^Water/Liquid Breakage"],
            productId: "SES_PH_D2C_1Y",
            productName: "Samsung Phillippines (D2C) ADLD - 1 Year",
            tenure: 12,
            tenureType: "Months",
        },
        {
            billingDate: {
                date: 10,
                dateSuffix: "th",
                month: "July",
                year: 2021,
            },
            clientProductDescription:
                "Samsung Philippines (D2C) ADLD - 1Y Monthly",
            clientProductName: "Samsung Philippines (D2C) ADLD - 1Y Monthly",
            coverageDate: {
                date: 19,
                dateSuffix: "th",
                month: "July",
                year: 2021,
            },
            covered: [
                "Accidental & liquid breakage repair coverage",
                "Hassle-free repair requests",
                "Free pick-up & delivery",
                "Repairs by Galaxy experts",
            ],
            description:
                "A protection subscription plan that covers your phone for as long as you need it to",
            isSubscription: true,
            plans: {
                actualProductPrice: 129,
                bandNo: "1.0",
                coverage: "ADLD",
                currency: "MYR",
                devicePriceEndingRange: 10000,
                devicePriceStartingrange: 0,
                discountedProductPrice: 129,
                fulfillmentSystemPrice: 129,
                paymentSystemPrice: 1,
                planId: "PHLSTG20200802000109",
                productId: "",
                totalRRPValueWithTax: 129,
            },
            productCoverage: ["Physical Breakage^Water/Liquid Breakage"],
            productId: "SES_PH_D2C_1M",
            productName: "Samsung Philippines (D2C) ADLD - 1Y Monthly",
            promoDefinition: "BOLT-100",
            tenure: 1,
            tenureType: "Monthly",
        },
    ];
    billingDate = null;
    coverageDate = null;
    payment_type_recurring = null;
    promoDefinition = null;
    fulfillmentSystemPrice = null;
    accessToken = null;
    planType = null;

    updateProductInfo = product => {
        this.productName = product.productName;
        this.productId = product.productId;
        this.planId =
            (product.plans && product.plans.planId) || product.plans.bandNo;
        this.deviceRrp = product.deviceRrp;
        this.coverageDesc = product.coverageDesc;
        this.productTenure = product.tenure;
        this.productTenureType = product.tenureType;
        this.productPrice =
            product.plans && product.plans.retailPriceValueWithTax;
        this.actualAmountWithoutPromotion =
            product.plans && product.plans.retailPriceValueWithTax * 2;
        this.currency = product.plans && product.plans.currency;
        this.productCoverage = product.productCoverage;
        this.actualProductPrice =
            product.plans && product.plans.actualProductPrice;
        this.discountedProductPrice =
            product.plans && product.plans.discountedProductPrice;
        this.paymentSystemPrice =
            product.plans && product.plans.paymentSystemPrice;
        this.fulfillmentSystemPrice =
            product.plans && product.plans.fulfillmentSystemPrice;
        this.billingDate = product.billingDate;
        this.coverageDate = product.coverageDate;
        this.promoDefinition = product.promoDefinition;
        this.isSubscription = product.isSubscription;
    };

    updatePaymentType = isRecurring => {
        this.payment_type_recurring = isRecurring;
    };

    saveProductArrToStore = productArr => {
        this.productList = productArr;
    };

    updateOrderNo = orderNo => {
        this.orderNo = orderNo;
    };

    updateInvoiceNumber = invoiceNo => {
        this.invoiceNumber = invoiceNo;
    };

    saveAccessToken = accessToken => {
        this.accessToken = accessToken;
    };

    updatePlanType = planType => {
        this.planType = planType;
    };
}

decorate(PlanStore, {
    planName: [persist, observable],
    productName: [persist, observable],
    productId: [persist, observable],
    planId: [persist, observable],
    productTenure: [persist, observable],
    productTenureType: [persist, observable],
    bandNo: [persist, observable],
    productPrice: [persist, observable],
    actualAmountWithoutPromotion: [persist, observable],
    coverageDesc: [persist, observable],
    currency: [persist, observable],
    isSubscription: [persist, observable],
    whatsCovered: [persist("object"), observable],
    orderNo: [persist, observable],
    selectedTenure: [persist, observable],
    productList: [persist("object"), observable],
    selectedProduct: [persist, observable],
    actualProductPrice: [persist, observable],
    discountedProductPrice: [persist, observable],
    fulfillmentSystemPrice: [persist, observable],
    payment_type_recurring: [persist, observable],
    paymentSystemPrice: [persist, observable],
    promoDefinition: [persist, observable],
    accessToken: [persist, observable],
    billingDate: [persist("object"), observable],
    coverageDate: [persist("object"), observable],
    saveAccessToken: action,
    updateOrderNo: action,
    updateProductInfo: action,
    updatePaymentType: action,
    planType: [persist, observable],
});
var planStore = new PlanStore();
export default planStore;

if (typeof window !== "undefined") {
    const hydrate = create({
        storage: localStorage,
        jsonify: true,
    });
    hydrate("planStore", planStore);
}
